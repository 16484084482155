import {
  Card,
  Row,
  Col,
  Typography,
  Radio,
  Upload,
  message,
  Button,
  Timeline,
} from "antd";
import Echat from "components/charts/Echat";
import LineChart from "components/charts/LineChart";
import React, { useState } from "react";
import { count, list } from "utils/HomeData";
import { Iconify } from "utils/Iconify";
import AntCard from "components/AntCard";
import card from "../assets/images/info-card-1.jpg";
import { timelineList } from "utils/HomeData";
const { Title, Paragraph, Text } = Typography;
export default function Home() {
  const [reverse, setReverse] = useState(false);
  const onChange = (e) => console.log(`radio checked:${e.target.value}`);
  const uploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed`);
      }
    },
  };
  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <AntCard xl={10}>
            <Echat />
          </AntCard>
          <AntCard xl={14}>
            <LineChart />
          </AntCard>
        </Row>
      </div>
    </>
  );
}

import React from "react";
import {
  Layout,
  Button,
  Typography,
  Form,
  Input,
  Col,
  Row,
  Switch,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import signinbg from "../assets/images/img-signin.jpg";
import { Header, Footer } from "components/Layout";

const { Content } = Layout;
const { Title } = Typography;

const SignIn = () => {
  const navigate = useNavigate();

  // Array of valid users (username and password)
  const validUsers = [
    { username: "kgangm", password: "password1" },
    { username: "tshepom", password: "password1" },
    { username: "siphok", password: "password1" },
  ];

  const onFinish = (values) => {
    // Check if the entered credentials exist in the array
    const user = validUsers.find(
      (user) =>
        user.username === values.username && user.password === values.password
    );

    if (user) {
      // If credentials exist, log the user in (success)
      message.success("Login successful!");
      console.log("User logged in: ", values);

      // Navigate to the dashboard after successful login
      navigate("/search"); // Update this to your desired route
    } else {
      // If credentials do not exist, show an error message
      message.error("Invalid username or password. Please try again.");
      console.log("Login failed: Invalid username or password.");
    }
  };

  const onFinishFailed = (errInfo) => {
    console.log("Error : ", errInfo);
  };

  function onChange(checked) {
    console.log(`switch to ${checked}`);
  }

  return (
    <>
      <div className="layout-default layout-signin">
        <Header btn="primary" />
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Sign In</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your username and password to sign in
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>

                <Form.Item
                  className="password"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input placeholder="Password" type="password" />
                </Form.Item>

                <Form.Item
                  name="remember"
                  className="align-center"
                  valuePropName="checked"
                  
                >
                  <Switch defaultChecked onChange={onChange} style={{ 
                    backgroundColor: "#619b04",
                    borderColor: "#619b04",
                  
                  }} />
                  Remember me
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ 
                      width: "100%",
                      backgroundColor: "#619b04",
                      borderColor: "#619b04",
                    
                    }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt="" />
            </Col>
          </Row>
        </Content>
        <Footer />
      </div>
    </>
  );
};

export default SignIn;
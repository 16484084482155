import React from "react";
import { Layout, Row, Col } from "antd";
import { Iconify } from "utils/Iconify";
const { Footer } = Layout;

export default function AntdFooter() {
  return (
    <Footer style={{ backgroundColor: "#fafafa" }}>
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            © Verified by 
            <a href="https://dytelligence.co.za/" className="font-weight-bold" target="_blank">
            Dytelligence IT Solutions
            </a>
            &nbsp;|&nbsp;
            <a href="https://monitor.mampudilabs.com/" onClick={() => console.log("Logging out...")}>
              Log Out
            </a>
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div className="footer-menu">
            <ul>
              {[].map((item, index) => (
                <li className="nav-item" key={index}>
                  <a
                    href="#pablo"
                    className="nav-link text-muted"
                    target="_blank"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </Footer>
  );
}
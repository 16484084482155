import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  message,
  Input,
  Tag,
  Modal,
  Button,
  Select,
  Collapse,
  Badge,
  Typography,
  Divider,
  Upload,
  DatePicker,
  Checkbox,
} from "antd";
import {
  UploadOutlined,
  SearchOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import Echat from "components/charts/Echat";
import LineChart from "components/charts/LineChart";
import { API_CONFIG } from "./config";

// Import html2pdf.js for PDF generation
import html2pdf from "html2pdf.js";

// Import xlsx and file-saver for exporting Excel
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

// Import moment for date handling
import moment from "moment";

const { Search } = Input;
const { Option } = Select;
const { Panel } = Collapse;
const { Text, Paragraph } = Typography;

export default function Tables() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const indigentSignaturePadRef = useRef(null);
  const officerSignaturePadRef = useRef(null);
  const [documents, setDocuments] = useState([]);
  const [employmentDetails, setEmploymentDetails] = useState([]);
  const [contactDetails, setContactDetails] = useState([]);

  // State for terms and conditions acceptance
  const [termsAccepted, setTermsAccepted] = useState(false);

  // State for Individuals Residing with the Applicant (Section C)
  const [householdMembers, setHouseholdMembers] = useState([]);

  // State for Financial Information (Section D)
  const [financialInfo, setFinancialInfo] = useState({
    wage: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
    pensionEmployer: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
    pensionDisability: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
    pensionOldAge: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
    boardRental: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
    childSupport: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
    informalWork: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
    otherIncome: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
  });

  // Ref for the modal content
  const modalContentRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_CONFIG.verificationUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": API_CONFIG.apiKey,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setTableData(data.rtv);
        setFilteredData(data.rtv);
        setLoading(false);
      } catch (error) {
        message.error("Failed to fetch data");
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateAge = (dob) => {
    if (!dob) return "N/A";
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const handleGenderFilter = (gender) => {
    if (gender === "All") {
      setFilteredData(tableData);
    } else {
      setFilteredData(tableData.filter((item) => item.gender === gender));
    }
  };

  const handleSearch = (value) => {
    const filtered = tableData.filter((item) => {
      const fullName = `${item.firstNames} ${item.surName}`.toLowerCase();
      return (
        item.idNumber.toLowerCase().includes(value.toLowerCase()) ||
        fullName.includes(value.toLowerCase())
      );
    });
    setFilteredData(filtered);
  };

  const handleRowClick = (record) => {
    setSelectedPerson(record);
    setEditedData({
      ...record,
      accountName: "",
      accountNumber: "",
      erfNumber: "",
      prepaidMeterNumber: "",
      applicationDate: "",
      accountAddress: "",
      wardNumber: null,
      CurrentEstimatedIncome: record.CurrentEstimatedIncome || "",
    });
    setIsModalVisible(true);

    // Clear the signature pads when a new person is selected
    if (indigentSignaturePadRef.current) {
      indigentSignaturePadRef.current.clear();
    }
    if (officerSignaturePadRef.current) {
      officerSignaturePadRef.current.clear();
    }

    // Clear documents
    setDocuments([]);

    // Reset terms acceptance
    setTermsAccepted(false);

    // Reset household members
    setHouseholdMembers([]);

    // Reset financial information
    setFinancialInfo({
      wage: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
      pensionEmployer: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
      pensionDisability: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
      pensionOldAge: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
      boardRental: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
      childSupport: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
      informalWork: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
      otherIncome: { husband: 0, wife: 0, adultLivingWithParents: 0, other: 0 },
    });

    // Extract employment details
    const employmentEntries = [];
    for (let i = 1; i <= 5; i++) {
      const empName = record[`Employment${i}_EmpName`];
      const empPosition = record[`Employment${i}_EmpPosition`];
      if (empName || empPosition) {
        employmentEntries.push({
          EmpName: empName || "",
          EmpPosition: empPosition || "",
        });
      }
    }
    setEmploymentDetails(
      employmentEntries.length ? employmentEntries : [{ EmpName: "", EmpPosition: "" }]
    );

    // Extract contact details
    const contactEntries = [];
    for (let i = 1; i <= 5; i++) {
      const number = record[`Contact${i}_Number`];
      const typeDescription = record[`Contact${i}_TypeDescription`];
      if (number || typeDescription) {
        contactEntries.push({
          Number: number || "",
          TypeDescription: typeDescription || "",
        });
      }
    }
    setContactDetails(
      contactEntries.length ? contactEntries : [{ Number: "", TypeDescription: "" }]
    );
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleAddClick = () => {
    navigate("/search");
  };

  const handleFieldChange = (key, value) => {
    setEditedData({ ...editedData, [key]: value });
  };

  const handleSaveData = async () => {
    if (!selectedPerson) return;

    setIsSaving(true);

    try {
      if (!termsAccepted) {
        message.error("Please accept the terms and conditions before saving.");
        setIsSaving(false);
        return;
      }

      const indigentSignatureData = indigentSignaturePadRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      const officerSignatureData = officerSignaturePadRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");

      // Prepare documents data
      const documentsData = documents.map((doc) => ({
        documentType: doc.documentType,
        file: doc.file.base64,
      }));

      // Prepare employment and contact data
      const employmentData = employmentDetails.reduce((acc, emp, index) => {
        acc[`Employment${index + 1}_EmpName`] = emp.EmpName;
        acc[`Employment${index + 1}_EmpPosition`] = emp.EmpPosition;
        return acc;
      }, {});

      const contactData = contactDetails.reduce((acc, contact, index) => {
        acc[`Contact${index + 1}_Number`] = contact.Number;
        acc[`Contact${index + 1}_TypeDescription`] = contact.TypeDescription;
        return acc;
      }, {});

      // Prepare household members data
      const householdData = householdMembers.map((member, index) => ({
        [`HouseholdMember${index + 1}_Name`]: member.name,
        [`HouseholdMember${index + 1}_Surname`]: member.surname,
        [`HouseholdMember${index + 1}_Relationship`]: member.relationship,
        [`HouseholdMember${index + 1}_IDNumber`]: member.idNumber,
        [`HouseholdMember${index + 1}_Income`]: member.income,
      }));

      // Prepare financial information data
      const financialData = {
        financialInfo,
      };

      const payload = {
        ...editedData,
        ...employmentData,
        ...contactData,
        ...financialData,
        indigentSignature: indigentSignatureData,
        officerSignature: officerSignatureData,
        supportingDocuments: documentsData,
        termsAccepted: termsAccepted,
      };

      // Include household data
      householdData.forEach((memberData) => {
        Object.assign(payload, memberData);
      });

      const response = await fetch(API_CONFIG.saveUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": API_CONFIG.apiKey,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to save data");
      }

      message.success("Data saved successfully");
      setIsModalVisible(false);
    } catch (error) {
      message.error("Error saving data");
    } finally {
      setIsSaving(false);
    }
  };

  const handleClearSignature = (signaturePadRef) => {
    signaturePadRef.current.clear();
  };

  // Function to handle exporting to PDF using a custom HTML template
  const handleExportPDF = () => {
    if (!selectedPerson) return;

    // Create a temporary div to hold the PDF content
    const pdfContent = document.createElement("div");
    pdfContent.style.padding = "20px";
    pdfContent.style.fontFamily = "Arial, sans-serif";
    pdfContent.style.fontSize = "12px";

    // Build the HTML content
    pdfContent.innerHTML = `
      <style>
        h1, h2, h3 {
          text-align: center;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 20px;
        }
        th, td {
          border: 1px solid #dddddd;
          padding: 8px;
          text-align: left;
          vertical-align: top;
        }
        th {
          background-color: #f2f2f2;
        }
        .section-title {
          background-color: #f2f2f2;
          padding: 10px;
          font-size: 16px;
          margin-bottom: 10px;
        }
        .signature {
          margin-top: 20px;
        }
        .signature img {
          width: 200px;
          height: auto;
        }
        .terms-conditions {
          margin-bottom: 20px;
        }
        .terms-conditions ul {
          list-style-type: disc;
          padding-left: 20px;
        }
        .terms-conditions p {
          text-align: justify;
        }
      </style>

      <h1>Indigent Application Form 2024/2025</h1>

      <!-- Section A - Account Information -->
      <div class="section-title">Section A - Account Information</div>
      <table>
        <tr>
          <th>Account Name</th>
          <td>${editedData.accountName || "N/A"}</td>
        </tr>
        <tr>
          <th>Account Number</th>
          <td>${editedData.accountNumber || "N/A"}</td>
        </tr>
        <tr>
          <th>Erf No</th>
          <td>${editedData.erfNumber || "N/A"}</td>
        </tr>
        <tr>
          <th>Eskom Pre-paid Meter Number</th>
          <td>${editedData.prepaidMeterNumber || "N/A"}</td>
        </tr>
        <tr>
          <th>Date of Application</th>
          <td>${editedData.applicationDate || "N/A"}</td>
        </tr>
        <tr>
          <th>Address</th>
          <td>${editedData.accountAddress || "N/A"}</td>
        </tr>
        <tr>
          <th>Ward Number</th>
          <td>${editedData.wardNumber || "N/A"}</td>
        </tr>
      </table>

      <!-- Section B - Terms and Conditions -->
      <div class="section-title">Section B - Terms and Conditions</div>
      <div class="terms-conditions">
        <ul>
          <li>Only households where the total household income is less or equal to R4 500.00 (Four thousand five hundred rand) per household of Council’s policy may apply for support.</li>
          <li>External verification will be done using the ITC as well a physical audit to confirm the household financial status. Upon signing this application, the consumer gives consent to such checks.</li>
          <li>False information or the withholding of information will disqualify anyone from further participation in the subsidy scheme, with the liability of immediate repayment of all allocations received plus interest and the risk of possible criminal proceedings being instituted.</li>
          <li>Should the gross income of a household increase and/or they exceed the approved limit during the subsidy time period (Increases, no longer unemployed), the registered consumer must immediately inform the municipality. Failure to comply could result in the repayment of all allocations plus interest and the risk of possible legal steps being instituted.</li>
          <li>No property may be sold by the indigent consumer within a timeframe of 36 months, after any write offs have been done, should this occur then the new owner will be held liable to pay the amounts that have been written off.</li>
          <li>The information contained in this document is not confidential. A list of approved applicants can be handed to councillors for comments, as well as publicly displayed.</li>
          <li>The City of Matlosana reserves the right to collect any outstanding debts on the account by means of the pre-paid services, in a portion not excessive in relation to the purchases made by the account holder.</li>
          <li>That City of Matlosana may issue my Free Basic Electricity (50 units) via my cell phone.</li>
        </ul>
        <p>“I, hereby declare that I am the registered owner/lessee of the above erf/stand and that the said property is inhabited and controlled by me. I further declare that I fully realize that should any of the above information be found to be incorrect or false, I shall be responsible for the repayment of any allocation received plus interest, as well as any debt written off, and I acknowledge that legal steps for the fraudulent declaration could be instituted against me. I confirm that I have read, understood, and accepted the terms and conditions stated above. I also give my permission that a credit check may be done on my details to determine the validity of my information and that my indigent status may be listed at an ITC institution. I/we the undersigned Applicant(s), hereby give consent to SARS to disclose my/our information to the Municipality [name of municipality] and the National Department of Cooperative Governance (COGTA) for purposes of verifying the details of my/our income levels that I/we have disclosed to the Municipality in support of my/our application for a municipal indigent grant.”</p>
      </div>

      <!-- Personal Information Section -->
      <div class="section-title">Personal Information</div>
      <table>
        <tr>
          <th>ID Number</th>
          <td>${selectedPerson.idNumber}</td>
        </tr>
        <tr>
          <th>First Names</th>
          <td>${selectedPerson.firstNames}</td>
        </tr>
        <tr>
          <th>Surname</th>
          <td>${selectedPerson.surName}</td>
        </tr>
        <tr>
          <th>Age</th>
          <td>${calculateAge(selectedPerson.dob)}</td>
        </tr>
        <tr>
          <th>Date of Birth</th>
          <td>${
            selectedPerson.dob ? new Date(selectedPerson.dob).toLocaleDateString() : "N/A"
          }</td>
        </tr>
        <tr>
          <th>Gender</th>
          <td>${selectedPerson.gender}</td>
        </tr>
        <tr>
          <th>Deceased Status</th>
          <td>${selectedPerson.deceasedStatus}</td>
        </tr>
        <tr>
          <th>Marital Status</th>
          <td>${selectedPerson.maritalStatus}</td>
        </tr>
        <tr>
          <th>Citizenship Status</th>
          <td>${selectedPerson.citizenshipStatus || "N/A"}</td>
        </tr>
        <tr>
          <th>Country of Birth</th>
          <td>${selectedPerson.countryOfBirth || "N/A"}</td>
        </tr>
      </table>

      <!-- Income Information Section -->
      <div class="section-title">Income Information</div>
      <table>
        <tr>
          <th>Estimated Income</th>
          <td>${editedData.CurrentEstimatedIncome || "N/A"}</td>
        </tr>
      </table>

      <!-- Address Information Section -->
      <div class="section-title">Address Information</div>
      <h3>Address 1</h3>
      <table>
        <tr>
          <th>Address Line 1</th>
          <td>${editedData.Address1_Line1 || selectedPerson.Address1_Line1 || "N/A"}</td>
        </tr>
        <tr>
          <th>Suburb</th>
          <td>${editedData.Address1_Line2 || selectedPerson.Address1_Line2 || "N/A"}</td>
        </tr>
        <tr>
          <th>City</th>
          <td>${editedData.Address1_Line3 || selectedPerson.Address1_Line3 || "N/A"}</td>
        </tr>
        <tr>
          <th>Province</th>
          <td>${editedData.Address1_Line4 || selectedPerson.Address1_Line4 || "N/A"}</td>
        </tr>
        <tr>
          <th>Postal Code</th>
          <td>${editedData.Address1_PostalCode || selectedPerson.Address1_PostalCode || "N/A"}</td>
        </tr>
        <tr>
          <th>Address Type</th>
          <td>${
            editedData.Address1_TypeDescription ||
            selectedPerson.Address1_TypeDescription ||
            "N/A"
          }</td>
        </tr>
      </table>

      <h3>Address 2</h3>
      <table>
        <tr>
          <th>Address Line 1</th>
          <td>${editedData.Address2_Line1 || selectedPerson.Address2_Line1 || "N/A"}</td>
        </tr>
        <tr>
          <th>Suburb</th>
          <td>${editedData.Address2_Line2 || selectedPerson.Address2_Line2 || "N/A"}</td>
        </tr>
        <tr>
          <th>City</th>
          <td>${editedData.Address2_Line3 || selectedPerson.Address2_Line3 || "N/A"}</td>
        </tr>
        <tr>
          <th>Province</th>
          <td>${editedData.Address2_Line4 || selectedPerson.Address2_Line4 || "N/A"}</td>
        </tr>
        <tr>
          <th>Postal Code</th>
          <td>${editedData.Address2_PostalCode || selectedPerson.Address2_PostalCode || "N/A"}</td>
        </tr>
        <tr>
          <th>Address Type</th>
          <td>${
            editedData.Address2_TypeDescription ||
            selectedPerson.Address2_TypeDescription ||
            "N/A"
          }</td>
        </tr>
      </table>

      <!-- Section C - Individuals Residing with the Applicant -->
      <div class="section-title">Section C - Individuals Residing with the Applicant</div>
      ${
        householdMembers.length > 0
          ? householdMembers
              .map(
                (member, index) => `
          <h3>Household Member ${index + 1}</h3>
          <table>
            <tr>
              <th>Name</th>
              <td>${member.name || "N/A"}</td>
            </tr>
            <tr>
              <th>Surname</th>
              <td>${member.surname || "N/A"}</td>
            </tr>
            <tr>
              <th>Relationship</th>
              <td>${member.relationship || "N/A"}</td>
            </tr>
            <tr>
              <th>ID Number</th>
              <td>${member.idNumber || "N/A"}</td>
            </tr>
            <tr>
              <th>Income</th>
              <td>${member.income || "N/A"}</td>
            </tr>
          </table>
        `
              )
              .join("")
          : "<p>No household members listed.</p>"
      }

      <!-- Section D - Financial Information -->
      <div class="section-title">Section D - Financial Information</div>
      <table>
        <tr>
          <th>Income Source</th>
          <th>Husband</th>
          <th>Wife</th>
          <th>Adult Living with Parents</th>
          <th>Other</th>
          <th>Total</th>
        </tr>
        ${Object.entries(financialInfo)
          .map(([incomeSource, amounts]) => {
            const total =
              amounts.husband +
              amounts.wife +
              amounts.adultLivingWithParents +
              amounts.other;
            return `
          <tr>
            <td>${incomeSource}</td>
            <td>${amounts.husband}</td>
            <td>${amounts.wife}</td>
            <td>${amounts.adultLivingWithParents}</td>
            <td>${amounts.other}</td>
            <td>${total}</td>
          </tr>
        `;
          })
          .join("")}
      </table>

      <!-- Employment Information Section -->
      <div class="section-title">Employment Information</div>
      ${
        employmentDetails.length > 0
          ? employmentDetails
              .map(
                (emp, index) => `
          <h3>Employment ${index + 1}</h3>
          <table>
            <tr>
              <th>Employer Name</th>
              <td>${emp.EmpName || "N/A"}</td>
            </tr>
            <tr>
              <th>Position</th>
              <td>${emp.EmpPosition || "N/A"}</td>
            </tr>
          </table>
        `
              )
              .join("")
          : "<p>No employment details provided.</p>"
      }

      <!-- Contact Information Section -->
      <div class="section-title">Contact Information</div>
      ${
        contactDetails.length > 0
          ? contactDetails
              .map(
                (contact, index) => `
          <h3>Contact ${index + 1}</h3>
          <table>
            <tr>
              <th>Contact Number</th>
              <td>${contact.Number || "N/A"}</td>
            </tr>
            <tr>
              <th>Contact Type</th>
              <td>${contact.TypeDescription || "N/A"}</td>
            </tr>
          </table>
        `
              )
              .join("")
          : "<p>No contact details provided.</p>"
      }

      <!-- Business Connection Details Section -->
      <div class="section-title">Business Connection Details</div>
      ${[1,2,3,4,5].map((num) => `
        <h3>Business Connection Details ${num}</h3>
        <table>
          <tr>
            <th>Commercial Status</th>
            <td>${selectedPerson[`CommercialStatus${num}`] || 'N/A'}</td>
          </tr>
          <tr>
            <th>Director Status</th>
            <td>${selectedPerson[`DirectorStatus${num}`] || 'N/A'}</td>
          </tr>
          <tr>
            <th>Appointment Date</th>
            <td>${selectedPerson[`AppointmentDate${num}`] || 'N/A'}</td>
          </tr>
          <tr>
            <th>Commercial Name</th>
            <td>${selectedPerson[`CommercialName${num}`] || 'N/A'}</td>
          </tr>
          <tr>
            <th>Registration No</th>
            <td>${selectedPerson[`RegistrationNo${num}`] || 'N/A'}</td>
          </tr>
          <tr>
            <th>Physical Address</th>
            <td>${selectedPerson[`PhysicalAddress${num}`] || 'N/A'}</td>
          </tr>
          <tr>
            <th>Postal Address</th>
            <td>${selectedPerson[`PostalAddress${num}`] || 'N/A'}</td>
          </tr>
          <tr>
            <th>Telephone No</th>
            <td>${selectedPerson[`TelephoneNo${num}`] || 'N/A'}</td>
          </tr>
        </table>
      `).join('')}

      <!-- Supporting Documents Section -->
      <div class="section-title">Supporting Documents</div>
      ${
        documents.length > 0
          ? documents
              .map(
                (doc, index) => `
          <h3>Document ${index + 1}</h3>
          <table>
            <tr>
              <th>Document Type</th>
              <td>${doc.documentType || "N/A"}</td>
            </tr>
            <tr>
              <th>File Name</th>
              <td>${doc.file.name || "N/A"}</td>
            </tr>
          </table>
        `
              )
              .join("")
          : "<p>No supporting documents uploaded.</p>"
      }

      <!-- Signatures Section -->
      <div class="section-title">Signatures</div>
      <div class="signature">
        <h3>Indigent Signature</h3>
        ${
          indigentSignaturePadRef.current && !indigentSignaturePadRef.current.isEmpty()
            ? `<img src="${indigentSignaturePadRef.current
                .getTrimmedCanvas()
                .toDataURL("image/png")}" />`
            : "<p>No signature provided.</p>"
        }
      </div>
      <div class="signature">
        <h3>Verification Officer Signature</h3>
        ${
          officerSignaturePadRef.current && !officerSignaturePadRef.current.isEmpty()
            ? `<img src="${officerSignaturePadRef.current
                .getTrimmedCanvas()
                .toDataURL("image/png")}" />`
            : "<p>No signature provided.</p>"
        }
      </div>
    `;

    // Use html2pdf.js to generate the PDF
    const options = {
      margin: [10, 10, 10, 10],
      filename: `${selectedPerson.firstNames}_${selectedPerson.surName}_Application.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    html2pdf()
      .set(options)
      .from(pdfContent)
      .save()
      .catch((err) => {
        console.error("Error generating PDF:", err);
        message.error("Failed to export PDF");
      });
  };

  // State to manage expanded panels
  const [expandedPanels, setExpandedPanels] = useState([]);

  const columns = [
    {
      title: "ID Number",
      dataIndex: "idNumber",
      key: "idNumber",
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text, record) =>
        `${record.firstNames || ""} ${record.surName || ""}`.trim(),
      sorter: (a, b) => a.firstNames.localeCompare(b.firstNames),
    },
    {
      title: "DHA Status",
      dataIndex: "idnoMatchStatus",
      key: "idnoMatchStatus",
      filters: [
        { text: "Matched", value: "Matched" },
        { text: "Not Matched", value: "Not Matched" },
      ],
      onFilter: (value, record) => record.idnoMatchStatus === value,
      render: (idnoMatchStatus) => (
        <Tag color={idnoMatchStatus === "Matched" ? "green" : "red"}>
          {idnoMatchStatus}
        </Tag>
      ),
    },
    {
      title: "Deceased Status",
      dataIndex: "deceasedStatus",
      key: "deceasedStatus",
      filters: [
        { text: "Alive", value: "Alive" },
        { text: "Deceased", value: "Deceased" },
      ],
      onFilter: (value, record) => record.deceasedStatus === value,
      render: (deceasedStatus) => (
        <Tag color={deceasedStatus === "Alive" ? "green" : "red"}>
          {deceasedStatus}
        </Tag>
      ),
    },
    {
      title: "Marital Status",
      dataIndex: "maritalStatus",
      key: "maritalStatus",
      filters: [
        { text: "MARRIED", value: "MARRIED" },
        { text: "SINGLE", value: "SINGLE" },
        { text: "DIVORCED", value: "DIVORCED" },
        { text: "WIDOWED", value: "WIDOWED" },
      ],
      onFilter: (value, record) => record.maritalStatus === value,
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
      key: "dob",
      render: (dob) => (dob ? new Date(dob).toLocaleDateString() : "N/A"),
      sorter: (a, b) => new Date(a.dob) - new Date(b.dob),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      filters: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
      ],
      onFilter: (value, record) => record.gender === value,
    },
  ];

  const onChange = (e) => {
    handleGenderFilter(e.target.value);
  };

  // Document Types
  const documentTypes = [
    "Municipal Account",
    "Id Copy",
    "Certified Birth Certificate",
    "Proof of Income",
    "Proof of SASA",
    "Income Affidavit",
    "Ward Councillor recommendation letter",
    "Owner Certified Death Certificate",
    "Proof of Total Gross Income",
    "Proof of Unemployment",
    "Court Order for Guardianship",
    "Proof of Vehicle ownership",
    "Copy of Prepaid Electricity Slip",
    "Proof of Other Properties",
  ];

  // Handle File Upload
  const handleFileChange = ({ file }) => {
    if (file.status === "done") {
      // Convert file to base64
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => {
        const base64 = reader.result;
        const newDocument = {
          file: { ...file, base64 },
          documentType: null, // To be selected
        };
        setDocuments([...documents, newDocument]);
      };
    }
  };

  // Handle Document Type Change
  const handleDocumentTypeChange = (index, value) => {
    const updatedDocuments = [...documents];
    updatedDocuments[index].documentType = value;
    setDocuments(updatedDocuments);
  };

  // Handle Remove Document
  const handleRemoveDocument = (index) => {
    const updatedDocuments = [...documents];
    updatedDocuments.splice(index, 1);
    setDocuments(updatedDocuments);
  };

  // Employment Details Handlers
  const handleEmploymentChange = (index, field, value) => {
    const updatedEmployment = [...employmentDetails];
    updatedEmployment[index][field] = value;
    setEmploymentDetails(updatedEmployment);
  };

  const addEmploymentEntry = () => {
    setEmploymentDetails([...employmentDetails, { EmpName: "", EmpPosition: "" }]);
  };

  const removeEmploymentEntry = (index) => {
    const updatedEmployment = [...employmentDetails];
    updatedEmployment.splice(index, 1);
    setEmploymentDetails(updatedEmployment);
  };

  // Contact Details Handlers
  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contactDetails];
    updatedContacts[index][field] = value;
    setContactDetails(updatedContacts);
  };

  const addContactEntry = () => {
    setContactDetails([...contactDetails, { Number: "", TypeDescription: "" }]);
  };

  const removeContactEntry = (index) => {
    const updatedContacts = [...contactDetails];
    updatedContacts.splice(index, 1);
    setContactDetails(updatedContacts);
  };

  // Household Members Handlers (Section C)
  const handleHouseholdMemberChange = (index, field, value) => {
    const updatedMembers = [...householdMembers];
    updatedMembers[index][field] = value;
    setHouseholdMembers(updatedMembers);
  };

  const addHouseholdMember = () => {
    setHouseholdMembers([
      ...householdMembers,
      { name: "", surname: "", relationship: "", idNumber: "", income: "" },
    ]);
  };

  const removeHouseholdMember = (index) => {
    const updatedMembers = [...householdMembers];
    updatedMembers.splice(index, 1);
    setHouseholdMembers(updatedMembers);
  };

  // Financial Information Handlers (Section D)
  const handleFinancialInfoChange = (incomeSource, person, value) => {
    const updatedFinancialInfo = { ...financialInfo };
    updatedFinancialInfo[incomeSource][person] = Number(value) || 0;
    setFinancialInfo(updatedFinancialInfo);
  };

  // Function to handle exporting to Excel (including all data columns)
  const handleExportExcel = () => {
    // Create a mapping of data keys to user-friendly column headers
    const columnMapping = {
      idNumber: "ID Number",
      firstNames: "First Names",
      surName: "Surname",
      idnoMatchStatus: "DHA Status",
      deceasedStatus: "Deceased Status",
      maritalStatus: "Marital Status",
      dob: "Date of Birth",
      gender: "Gender",
      citizenshipStatus: "Citizenship Status",
      countryOfBirth: "Country of Birth",
      CurrentEstimatedIncome: "Estimated Income",
      // Address1 fields
      Address1_Line1: "Address1 Line1",
      Address1_Line2: "Address1 Suburb",
      Address1_Line3: "Address1 City",
      Address1_Line4: "Address1 Province",
      Address1_PostalCode: "Address1 Postal Code",
      Address1_TypeDescription: "Address1 Type",
      // Address2 fields
      Address2_Line1: "Address2 Line1",
      Address2_Line2: "Address2 Suburb",
      Address2_Line3: "Address2 City",
      Address2_Line4: "Address2 Province",
      Address2_PostalCode: "Address2 Postal Code",
      Address2_TypeDescription: "Address2 Type",
      // New fields
      accountName: "Account Name",
      accountNumber: "Account Number",
      erfNumber: "Erf No",
      prepaidMeterNumber: "Eskom Pre-paid Meter Number",
      applicationDate: "Date of Application",
      accountAddress: "Address",
      wardNumber: "Ward Number",
      // Employment and Contact Details will be handled separately
    };

    // Function to flatten nested objects
    const flattenObject = (obj, prefix = "") => {
      let result = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const newKey = prefix ? `${prefix}_${key}` : key;
          if (typeof value === "object" && value !== null && !Array.isArray(value)) {
            result = { ...result, ...flattenObject(value, newKey) };
          } else {
            result[newKey] = value;
          }
        }
      }
      return result;
    };

    // Map your table data to the desired format
    const data = filteredData.map((item) => {
      // Flatten the item object
      const flatItem = flattenObject(item);

      const dataItem = {};
      for (const key in flatItem) {
        if (flatItem.hasOwnProperty(key)) {
          const columnName = columnMapping[key] || key;
          let value = flatItem[key];

          // Format dates
          if (key === "dob" || key.startsWith("AppointmentDate")) {
            value = value ? new Date(value).toLocaleDateString() : "N/A";
          }

          dataItem[columnName] = value;
        }
      }

      // Include employment details
      for (let i = 1; i <= 5; i++) {
        const empName = item[`Employment${i}_EmpName`];
        const empPosition = item[`Employment${i}_EmpPosition`];
        if (empName || empPosition) {
          dataItem[`Employer Name ${i}`] = empName || "";
          dataItem[`Position ${i}`] = empPosition || "";
        }
      }

      // Include contact details
      for (let i = 1; i <= 5; i++) {
        const number = item[`Contact${i}_Number`];
        const typeDescription = item[`Contact${i}_TypeDescription`];
        if (number || typeDescription) {
          dataItem[`Contact Number ${i}`] = number || "";
          dataItem[`Contact Type ${i}`] = typeDescription || "";
        }
      }

      return dataItem;
    });

    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Adjust column widths if necessary
    const columnWidths = [];
    for (const key in data[0]) {
      if (data[0].hasOwnProperty(key)) {
        columnWidths.push({ wch: key.length + 10 });
      }
    }
    worksheet["!cols"] = columnWidths;

    const workbook = {
      Sheets: { Data: worksheet },
      SheetNames: ["Data"],
    };

    // Convert the workbook to binary array
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    // Create a Blob from the binary array
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Save the Blob as an Excel file
    saveAs(dataBlob, "Indigent_Data.xlsx");
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Indigent Data"
              extra={
                <Radio.Group onChange={onChange} defaultValue="All">
                  <Radio.Button value="All">All</Radio.Button>
                  <Radio.Button value="Male">Male</Radio.Button>
                  <Radio.Button value="Female">Female</Radio.Button>
                </Radio.Group>
              }
            >
              <Button
                type="primary"
                onClick={handleAddClick}
                style={{
                  backgroundColor: "#28a745",
                  borderColor: "#28a745",
                  marginRight: 8,
                }}
              >
                New Verification
              </Button>
  
              <Button
                type="primary"
                onClick={handleExportExcel}
                style={{ marginRight: 8 }}
              >
                Export to Excel
              </Button>
  
              <Search
                placeholder="Search by ID or Full Name"
                enterButton={<SearchOutlined />}
                onSearch={handleSearch}
                style={{ width: 300, marginLeft: 16 }}
              />
              <div className="table-responsive" style={{ marginTop: 16 }}>
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  loading={loading}
                  pagination={{ pageSize: 10 }}
                  rowKey="id"
                  onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                  })}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
  
      <Modal
        title="Indigent Application Form 2024/2025"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={[
          <Button
            key="export"
            onClick={handleExportPDF}
            style={{ marginRight: "auto" }}
          >
            Export to PDF
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleSaveData}
            loading={isSaving}
            style={{ backgroundColor: "#28a745", borderColor: "#28a745" }}
          >
            Save
          </Button>,
        ]}
        width={800}
      >
        {selectedPerson && (
          <div ref={modalContentRef}>
            <Collapse
              activeKey={expandedPanels}
              onChange={(keys) => setExpandedPanels(keys)}
            >
              {/* Section A - Account Information */}
              <Panel header="Section A - Account Information" key="accountInfo">
                {/* Account Name */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Account Name:</Text>
                  <Input
                    value={editedData.accountName || ""}
                    onChange={(e) =>
                      handleFieldChange("accountName", e.target.value)
                    }
                    placeholder="Enter Account Name"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
  
                {/* Account Number */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Account Number:</Text>
                  <Input
                    value={editedData.accountNumber || ""}
                    onChange={(e) =>
                      handleFieldChange("accountNumber", e.target.value)
                    }
                    placeholder="Enter Account Number"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
  
                {/* Erf No */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Erf No:</Text>
                  <Input
                    value={editedData.erfNumber || ""}
                    onChange={(e) =>
                      handleFieldChange("erfNumber", e.target.value)
                    }
                    placeholder="Enter Erf No"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
  
                {/* Eskom Pre-paid Meter Number */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Eskom Pre-paid Meter Number:</Text>
                  <Input
                    value={editedData.prepaidMeterNumber || ""}
                    onChange={(e) =>
                      handleFieldChange("prepaidMeterNumber", e.target.value)
                    }
                    placeholder="Enter Eskom Pre-paid Meter Number"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
  
                {/* Date of Application */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Date of Application:</Text>
                  <DatePicker
                    value={
                      editedData.applicationDate
                        ? moment(editedData.applicationDate)
                        : null
                    }
                    onChange={(date) =>
                      handleFieldChange(
                        "applicationDate",
                        date ? date.format("YYYY-MM-DD") : null
                      )
                    }
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
  
                {/* Address */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Address:</Text>
                  <Input
                    value={editedData.accountAddress || ""}
                    onChange={(e) =>
                      handleFieldChange("accountAddress", e.target.value)
                    }
                    placeholder="Enter Address"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
  
                {/* Ward Number */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Ward Number:</Text>
                  <Select
                    value={editedData.wardNumber || undefined}
                    onChange={(value) => handleFieldChange("wardNumber", value)}
                    placeholder="Select Ward Number"
                    style={{ width: "100%", marginTop: 8 }}
                  >
                    {Array.from({ length: 10 }, (_, i) => (
                      <Option key={i + 1} value={i + 1}>
                        {i + 1}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Panel>
  
              {/* Section B - Terms and Conditions */}
              <Panel header="Section B - Terms and Conditions" key="termsAndConditions">
                <div style={{ marginBottom: 16 }}>
                  <Paragraph>
                    • Only households where the total household income is less or equal to R4 500.00 (Four thousand five hundred rand) per household of Council’s policy may apply for support.
                  </Paragraph>
                  <Paragraph>
                    • External verification will be done using the ITC as well a physical audit to confirm the household financial status. Upon signing this application, the consumer gives consent to such checks.
                  </Paragraph>
                  <Paragraph>
                    • False information or the withholding of information will disqualify anyone from further participation in the subsidy scheme, with the liability of immediate repayment of all allocations received plus interest and the risk of possible criminal proceedings being instituted.
                  </Paragraph>
                  <Paragraph>
                    • Should the gross income of a household increase and/or they exceed the approved limit during the subsidy time period (Increases, no longer unemployed), the registered consumer must immediately inform the municipality. Failure to comply could result in the repayment of all allocations plus interest and the risk of possible legal steps being instituted.
                  </Paragraph>
                  <Paragraph>
                    • No property may be sold by the indigent consumer within a timeframe of 36 months, after any write offs have been done, should this occur then the new owner will be held liable to pay the amounts that have been written off.
                  </Paragraph>
                  <Paragraph>
                    • The information contained in this document is not confidential. A list of approved applicants can be handed to councillors for comments, as well as publicly displayed.
                  </Paragraph>
                  <Paragraph>
                    • The City of Matlosana reserves the right to collect any outstanding debts on the account by means of the pre-paid services, in a portion not excessive in relation to the purchases made by the account holder.
                  </Paragraph>
                  <Paragraph>
                    • That City of Matlosana may issue my Free Basic Electricity (50 units) via my cell phone.
                  </Paragraph>
                  <Paragraph>
                    “I, hereby declare that I am the registered owner/lessee of the above erf/stand and that the said property is inhabited and controlled by me. I further declare that I fully realize that should any of the above information be found to be incorrect or false, I shall be responsible for the repayment of any allocation received plus interest, as well as any debt written off, and I acknowledge that legal steps for the fraudulent declaration could be instituted against me. I confirm that I have read, understood, and accepted the terms and conditions stated above. I also give my permission that a credit check may be done on my details to determine the validity of my information and that my indigent status may be listed at an ITC institution. I/we the undersigned Applicant(s), hereby give consent to SARS to disclose my/our information to the Municipality [name of municipality] and the National Department of Cooperative Governance (COGTA) for purposes of verifying the details of my/our income levels that I/we have disclosed to the Municipality in support of my/our application for a municipal indigent grant.”
                  </Paragraph>
                </div>
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                >
                  I have read and accept the terms and conditions.
                </Checkbox>
              </Panel>
  
              {/* Personal Information Section */}
              <Panel header="Section B - Personal Information" key="1">
                <Text strong style={{ fontSize: 16 }}>
                  <Badge
                    count="QUALIFYING STATUS"
                    style={{
                      backgroundColor:
                        selectedPerson.idnoMatchStatus === "Matched"
                          ? "green"
                          : "red",
                    }}
                  >
                    {selectedPerson.idnoMatchStatus}
                  </Badge>
                </Text>
                <Divider />
                <div style={{ marginBottom: 8 }}>
                  <Text strong>ID Number:</Text> {selectedPerson.idNumber}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>First Names:</Text> {selectedPerson.firstNames}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Surname:</Text> {selectedPerson.surName}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Age:</Text> {calculateAge(selectedPerson.dob)}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Date of Birth:</Text>{" "}
                  {selectedPerson.dob
                    ? new Date(selectedPerson.dob).toLocaleDateString()
                    : "N/A"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Gender:</Text> {selectedPerson.gender}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Deceased Status:</Text>
                  <span
                    style={{
                      color:
                        selectedPerson.deceasedStatus === "Alive"
                          ? "green"
                          : "red",
                    }}
                  >
                    {selectedPerson.deceasedStatus}
                  </span>
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Marital Status:</Text>
                  <span style={{ color: "green" }}>
                    {selectedPerson.maritalStatus}
                  </span>
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Citizenship Status:</Text>{" "}
                  {selectedPerson.citizenshipStatus || "N/A"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Country of Birth:</Text>{" "}
                  {selectedPerson.countryOfBirth || "N/A"}
                </div>
              </Panel>
  
              {/* Income Information Section */}
              <Panel header="Income Information" key="2">
                <Text strong style={{ fontSize: 16 }}>
                  Estimated Income:
                </Text>
                <Input
                  value={
                    editedData.CurrentEstimatedIncome ||
                    selectedPerson.CurrentEstimatedIncome
                  }
                  onChange={(e) =>
                    handleFieldChange("CurrentEstimatedIncome", e.target.value)
                  }
                  type="number"
                  placeholder="Enter estimated income"
                  style={{ width: "100%", marginTop: 8 }}
                />
              </Panel>
  
              {/* Address Information Section */}
              <Panel header="Address Information" key="3">
                {/* Address1 Information */}
                <Divider orientation="left">Address 1</Divider>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Address Line 1:</Text>
                  <Input
                    value={
                      editedData.Address1_Line1 || selectedPerson.Address1_Line1
                    }
                    onChange={(e) =>
                      handleFieldChange("Address1_Line1", e.target.value)
                    }
                    placeholder="Enter Address Line 1"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                {/* Additional address fields */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Address Type:</Text>
                  <Select
                    value={
                      editedData.Address1_TypeDescription ||
                      selectedPerson.Address1_TypeDescription
                    }
                    onChange={(value) =>
                      handleFieldChange("Address1_TypeDescription", value)
                    }
                    style={{ width: "100%", marginTop: 8 }}
                  >
                    <Option value="Residential">Residential</Option>
                    <Option value="Postal">Postal</Option>
                  </Select>
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Suburb:</Text>
                  <Input
                    value={
                      editedData.Address1_Line2 || selectedPerson.Address1_Line2
                    }
                    onChange={(e) =>
                      handleFieldChange("Address1_Line2", e.target.value)
                    }
                    placeholder="Enter Suburb"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>City:</Text>
                  <Input
                    value={
                      editedData.Address1_Line3 || selectedPerson.Address1_Line3
                    }
                    onChange={(e) =>
                      handleFieldChange("Address1_Line3", e.target.value)
                    }
                    placeholder="Enter City"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Province:</Text>
                  <Input
                    value={
                      editedData.Address1_Line4 || selectedPerson.Address1_Line4
                    }
                    onChange={(e) =>
                      handleFieldChange("Address1_Line4", e.target.value)
                    }
                    placeholder="Enter Province"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Postal Code:</Text>
                  <Input
                    value={
                      editedData.Address1_PostalCode ||
                      selectedPerson.Address1_PostalCode
                    }
                    onChange={(e) =>
                      handleFieldChange("Address1_PostalCode", e.target.value)
                    }
                    placeholder="Enter Postal Code"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
  
                {/* Address2 Information */}
                <Divider orientation="left">Address 2</Divider>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Address Line 1:</Text>
                  <Input
                    value={
                      editedData.Address2_Line1 || selectedPerson.Address2_Line1
                    }
                    onChange={(e) =>
                      handleFieldChange("Address2_Line1", e.target.value)
                    }
                    placeholder="Enter Address Line 1"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                {/* Additional address fields */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Address Type:</Text>
                  <Select
                    value={
                      editedData.Address2_TypeDescription ||
                      selectedPerson.Address2_TypeDescription
                    }
                    onChange={(value) =>
                      handleFieldChange("Address2_TypeDescription", value)
                    }
                    style={{ width: "100%", marginTop: 8 }}
                  >
                    <Option value="Residential">Residential</Option>
                    <Option value="Postal">Postal</Option>
                  </Select>
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Suburb:</Text>
                  <Input
                    value={
                      editedData.Address2_Line2 || selectedPerson.Address2_Line2
                    }
                    onChange={(e) =>
                      handleFieldChange("Address2_Line2", e.target.value)
                    }
                    placeholder="Enter Suburb"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>City:</Text>
                  <Input
                    value={
                      editedData.Address2_Line3 || selectedPerson.Address2_Line3
                    }
                    onChange={(e) =>
                      handleFieldChange("Address2_Line3", e.target.value)
                    }
                    placeholder="Enter City"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Province:</Text>
                  <Input
                    value={
                      editedData.Address2_Line4 || selectedPerson.Address2_Line4
                    }
                    onChange={(e) =>
                      handleFieldChange("Address2_Line4", e.target.value)
                    }
                    placeholder="Enter Province"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Postal Code:</Text>
                  <Input
                    value={
                      editedData.Address2_PostalCode ||
                      selectedPerson.Address2_PostalCode
                    }
                    onChange={(e) =>
                      handleFieldChange("Address2_PostalCode", e.target.value)
                    }
                    placeholder="Enter Postal Code"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
              </Panel>
  
              {/* Employment Information Section */}
              <Panel header="Employment Information" key="4">
                {employmentDetails.map((emp, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: 16,
                      borderBottom: "1px solid #f0f0f0",
                      paddingBottom: 16,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text strong>Employment {index + 1}:</Text>
                      {index > 0 && (
                        <Button
                          type="danger"
                          icon={<MinusCircleOutlined />}
                          onClick={() => removeEmploymentEntry(index)}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Employer Name:</Text>
                      <Input
                        value={emp.EmpName}
                        onChange={(e) =>
                          handleEmploymentChange(index, "EmpName", e.target.value)
                        }
                        placeholder="Enter Employer Name"
                        style={{ width: "100%", marginTop: 8 }}
                      />
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Position:</Text>
                      <Input
                        value={emp.EmpPosition}
                        onChange={(e) =>
                          handleEmploymentChange(
                            index,
                            "EmpPosition",
                            e.target.value
                          )
                        }
                        placeholder="Enter Position"
                        style={{ width: "100%", marginTop: 8 }}
                      />
                    </div>
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={addEmploymentEntry}
                  style={{ width: "100%" }}
                >
                  <PlusOutlined /> Add Employment
                </Button>
              </Panel>
  
              {/* Contact Information Section */}
              <Panel header="Contact Information" key="5">
                {contactDetails.map((contact, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: 16,
                      borderBottom: "1px solid #f0f0f0",
                      paddingBottom: 16,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text strong>Contact {index + 1}:</Text>
                      {index > 0 && (
                        <Button
                          type="danger"
                          icon={<MinusCircleOutlined />}
                          onClick={() => removeContactEntry(index)}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Contact Number:</Text>
                      <Input
                        value={contact.Number}
                        onChange={(e) =>
                          handleContactChange(index, "Number", e.target.value)
                        }
                        placeholder="Enter Contact Number"
                        style={{ width: "100%", marginTop: 8 }}
                      />
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Contact Type:</Text>
                      <Select
                        value={contact.TypeDescription}
                        onChange={(value) =>
                          handleContactChange(index, "TypeDescription", value)
                        }
                        style={{ width: "100%", marginTop: 8 }}
                        placeholder="Select Contact Type"
                      >
                        <Option value="Mobile">Mobile</Option>
                        <Option value="Home">Home</Option>
                        <Option value="Work">Work</Option>
                      </Select>
                    </div>
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={addContactEntry}
                  style={{ width: "100%" }}
                >
                  <PlusOutlined /> Add Contact
                </Button>
              </Panel>
  
              {/* Business Connection Details Section */}
              <Panel header="Business Connection Details" key="6">
                <Collapse defaultActiveKey={["1", "2", "3", "4", "5"]}>
                  {[1, 2, 3, 4, 5].map((num) => (
                    <Panel
                      header={`Business Connection Details ${num}`}
                      key={num}
                    >
                      <div style={{ marginBottom: 8 }}>
                        <Text strong>Commercial Status:</Text>
                        <span
                          style={{
                            color:
                              selectedPerson[`CommercialStatus${num}`] ===
                              "In Business"
                                ? "green"
                                : "red",
                          }}
                        >
                          {selectedPerson[`CommercialStatus${num}`] || "N/A"}
                        </span>
                      </div>
                      <div style={{ marginBottom: 8 }}>
                        <Text strong>Director Status:</Text>
                        <span
                          style={{
                            color:
                              selectedPerson[`DirectorStatus${num}`] === "Active"
                                ? "green"
                                : "red",
                          }}
                        >
                          {selectedPerson[`DirectorStatus${num}`] || "N/A"}
                        </span>
                      </div>
                      <div style={{ marginBottom: 8 }}>
                        <Text strong>Appointment Date:</Text>{" "}
                        {selectedPerson[`AppointmentDate${num}`] || "N/A"}
                      </div>
                      <div style={{ marginBottom: 8 }}>
                        <Text strong>Commercial Name:</Text>{" "}
                        {selectedPerson[`CommercialName${num}`] || "N/A"}
                      </div>
                      <div style={{ marginBottom: 8 }}>
                        <Text strong>Registration No:</Text>{" "}
                        {selectedPerson[`RegistrationNo${num}`] || "N/A"}
                      </div>
                      <div style={{ marginBottom: 8 }}>
                        <Text strong>Physical Address:</Text>{" "}
                        {selectedPerson[`PhysicalAddress${num}`] || "N/A"}
                      </div>
                      <div style={{ marginBottom: 8 }}>
                        <Text strong>Postal Address:</Text>{" "}
                        {selectedPerson[`PostalAddress${num}`] || "N/A"}
                      </div>
                      <div style={{ marginBottom: 8 }}>
                        <Text strong>Telephone No:</Text>{" "}
                        {selectedPerson[`TelephoneNo${num}`] || "N/A"}
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              </Panel>
  
              {/* Supporting Documents Section */}
              <Panel header="Supporting Documents" key="supportingDocuments">
                <div style={{ marginBottom: 16 }}>
                  <Upload
                    accept="image/*,application/pdf"
                    customRequest={({ file, onSuccess }) => {
                      setTimeout(() => {
                        onSuccess("ok");
                      }, 0);
                    }}
                    onChange={handleFileChange}
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>Upload Document</Button>
                  </Upload>
                  <Divider />
                  {documents.map((doc, index) => (
                    <div key={index} style={{ marginBottom: 16 }}>
                      <div style={{ marginBottom: 8 }}>
                        <Text strong>Document Type:</Text>
                        <Select
                          value={doc.documentType || undefined}
                          onChange={(value) =>
                            handleDocumentTypeChange(index, value)
                          }
                          style={{ width: "100%", marginTop: 8 }}
                          placeholder="Select Document Type"
                        >
                          {documentTypes.map((type) => (
                            <Option key={type} value={type}>
                              {type}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div style={{ marginBottom: 8 }}>
                        <Text strong>File:</Text> {doc.file.name}
                      </div>
                      <Button
                        type="danger"
                        onClick={() => handleRemoveDocument(index)}
                      >
                        Remove Document
                      </Button>
                      <Divider />
                    </div>
                  ))}
                </div>
              </Panel>
  
              {/* Signature Section */}
              <Panel header="Signatures" key="7">
                <Collapse defaultActiveKey={["indigentSignature", "officerSignature"]}>
                  {/* Indigent Signature Panel */}
                  <Panel header="Indigent Signature" key="indigentSignature">
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Signature:</Text>
                      <SignatureCanvas
                        ref={indigentSignaturePadRef}
                        penColor="black"
                        canvasProps={{
                          width: 500,
                          height: 200,
                          className: "signatureCanvas",
                        }}
                        style={{
                          border: "1px solid #000",
                          width: "100%",
                          marginTop: 8,
                        }}
                      />
                      <Button
                        onClick={() => handleClearSignature(indigentSignaturePadRef)}
                        style={{
                          backgroundColor: "#28a745",
                          borderColor: "#28a745",
                          color: "#ffffff",
                          marginTop: 8,
                        }}
                      >
                        Clear Signature
                      </Button>
                    </div>
                  </Panel>
  
                  {/* Verification Officer Signature Panel */}
                  <Panel
                    header="Verification Officer Signature"
                    key="officerSignature"
                  >
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Signature:</Text>
                      <SignatureCanvas
                        ref={officerSignaturePadRef}
                        penColor="black"
                        canvasProps={{
                          width: 500,
                          height: 200,
                          className: "signatureCanvas",
                        }}
                        style={{
                          border: "1px solid #000",
                          width: "100%",
                          marginTop: 8,
                        }}
                      />
                      <Button
                        onClick={() => handleClearSignature(officerSignaturePadRef)}
                        style={{
                          backgroundColor: "#28a745",
                          borderColor: "#28a745",
                          color: "#ffffff",
                          marginTop: 8,
                        }}
                      >
                        Clear Signature
                      </Button>
                    </div>
                  </Panel>
                </Collapse>
              </Panel>
            </Collapse>
          </div>
        )}
      </Modal>
  
      <div className="layout-content" style={{ marginTop: "40px" }}>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={10}>
            <Card bordered={false}>
              <Echat />
            </Card>
          </Col>
          <Col xs={24} lg={14}>
            <Card bordered={false}>
              <LineChart />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}